import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ErrorCard from '../ErrorCard'
import {Badge, Button} from "react-bootstrap";

class VinSuggestionsCard extends Component {

    render() {
        return (
            <ErrorCard header="VIN Not Found">
                <p>
                    The VIN you entered appears to be invalid.
                </p>
                <p>
                    If you meant to enter one of the VINs listed below, please select it.
                </p>

                {this.props.suggestions.map(suggestion => {
                    return (
                        <div key={suggestion.vin}>
                            <Button
                                onClick={this.props.onSuggestionClick}
                                className={"m-2 font-monospace"}
                            >
                                {suggestion.vin}
                            </Button>
                            {suggestion.decode}
                        </div>
                    )
                })}
            </ErrorCard>
        )
    }

}

VinSuggestionsCard.propTypes = {
    onSuggestionClick: PropTypes.func.isRequired,
    suggestions: PropTypes.array
}

export default VinSuggestionsCard