import React from 'react';
import {createRoot} from "react-dom/client"
import "./css/App.css"
import "./css/components/pages/ReportPage.css"
import "./css/components/unsupportedBrowser/UnsupportedBrowser.css"
import "./css/components/Navigation.css"
import "bootstrap/dist/css/bootstrap.min.css"
import Routes from "./routes"
import {Provider} from "react-redux"
import configureStore from "./configureStore"
import App from "./App";
import {BrowserRouter} from "react-router-dom";

let store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <App>
                <Routes/>
            </App>
        </Provider>
    </BrowserRouter>
);
