import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import './css/App.css';
import ReportPage from "./components/pages/ReportPage"
import LoginPage from "./components/pages/LoginPage"

import * as jwtActions from "./actions/jwtActions"
import {Routes as ReactRoutes, Route} from "react-router-dom";
import {withRouter} from "./wrapper/withRouter";

class Routes extends Component {
    constructor(props, context) {
        super(props, context)
        this.redirectAuth = this.redirectAuth.bind(this)
    }

    redirectAuth(nextState, replaceState, callback) {
        let accessToken = localStorage.getItem('access_token')

        if (!accessToken && nextState.location.pathname === '/') {
            callback()
        } else {
            this.props.actions.isTokenValid(this.props.navigate).then(isAuthenticated => {
                if (isAuthenticated && nextState.location.pathname !== '/report') {
                    replaceState({
                        pathname: '/report'
                    })
                } else if (!isAuthenticated && nextState.location.pathname !== '/') {
                    replaceState({
                        pathname: '/'
                    })
                }

                callback()
            })
        }
    }

    render() {
        return (
                <ReactRoutes>
                    <Route path={"/"} element={<LoginPage/>} onEnter={() => this.redirectAuth(this.props.navigate)}/>
                    <Route path="report" element={<ReportPage/>} onEnter={() => this.redirectAuth(this.props.navigate)}/>
                </ReactRoutes>
        );
    }
}


function mapStateToProps(state, ownProps) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(jwtActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
