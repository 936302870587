import {combineReducers} from 'redux'
import ajaxCallsInProgress from './ajaxStatusReducer'
import report from './reportReducer'
import jwtReducer from './jwtReducer'
import error from './errorReducer'

const rootReducer = combineReducers({
    report,
    error,
    ajaxCallsInProgress,
    jwtResult: jwtReducer
})

export default rootReducer