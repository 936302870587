import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {
    Table,
    Form,
    Button,
    Row,
    Col,
    InputGroup,
    Card,
    Popover,
    OverlayTrigger, Container
} from 'react-bootstrap'
import 'font-awesome/css/font-awesome.css';
import FontAwesome from 'react-fontawesome'
import * as reportActions from '../../actions/reportActions'
import * as jwtActions from '../../actions/jwtActions'
import VinSuggestionsCard from '../errors/cards/VinSuggestionsCard'
import SystemErrorCard from '../errors/cards/SystemErrorCard'
import InvalidVinCard from '../errors/cards/InvalidVinCard'
import NoDataCard from '../errors/cards/NoDataCard'
import '../../css/components/pages/ReportPage.css'
import PropTypes from 'prop-types'
import {withRouter} from "../../wrapper/withRouter";

class ReportPage extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            vin: ''
        }

        this.onChangeVin = this.onChangeVin.bind(this)
        this.onSuggestionClick = this.onSuggestionClick.bind(this)
        this.submit = this.submit.bind(this)
        this.renderVinRun = this.renderVinRun.bind(this)
        this.renderResultCards = this.renderResultCards.bind(this)
        this.renderErrorCardIfNecessary = this.renderErrorCardIfNecessary.bind(this)
        this.queryForNewReport = this.queryForNewReport.bind(this)
    }

    render() {
        return (<>
                {this.renderVinRun()}
                {this.renderLogout()}
                {this.renderErrorCardIfNecessary()}
                {this.renderResultCards()}
            </>
        )
    }

    renderLogout() {
        if (this.props.jwtResult.access_token) {
            return (
                <Button
                    id={"logout"}
                    variant={"info"}
                    onClick={() => this.props.jwtActions.logout({}, this.props.navigate)}
                >
                    Logout
                </Button>
            )
        }
    }

    renderErrorCardIfNecessary() {
        const error = this.props.error

        if (error.isInvalidVin) {
            if (error.hasSuggestions) {
                return (
                    <VinSuggestionsCard suggestions={error.suggestions} onSuggestionClick={this.onSuggestionClick}/>
                )
            } else {
                return (
                    <InvalidVinCard vin={error.vin}/>
                )
            }
        } else if (error.isNoDataVin) {
            return (
                <NoDataCard vin={error.vin}/>
            )
        } else if (error.isSystemError) {
            return (
                <SystemErrorCard/>
            )
        }
    }

    onSuggestionClick(event) {
        const vin = event.target.innerHTML
        this.queryForNewReport(vin)
    }

    renderResultCards() {
        const arrayOfKeys = Object.keys(this.props.report)

        return arrayOfKeys.map(key => {
            return (
                <Row
                    key={key}
                    className={"justify-content-md-center"}
                >
                    <Col md={"auto"}>
                        {this.renderResultCard(key, this.props.report[key])}
                    </Col>
                </Row>
            )
        })
    }

    renderVinRun() {
        const isSubmitDisabled = this.props.ajaxCallsInProgress > 0
        const loadingClass = this.props.ajaxCallsInProgress > 0 ? 'loading' : ''
        const textVisibility = this.props.ajaxCallsInProgress > 0 ? 'hidden' : 'visible'

        return (
            <Container>
                <Row className={"justify-content-md-center"}>
                    <Col md={"auto"}>
                        <InputGroup>
                            <Form.Control
                                autoFocus={true}
                                type={"text"}
                                value={this.state.vin}
                                id={"vin"}
                                onChange={this.onChangeVin}
                                placeholder={"VIN"}
                            />
                            <Button
                                disabled={isSubmitDisabled}
                                variant={"success"}
                                type={"submit"}
                                id={"submit"}
                                className={loadingClass}
                                onClick={this.submit}
                            >
                                <span style={{visibility: textVisibility}}>Run</span>
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Container>
        )
    }

    renderResultCard(cardTitle, json) {
        if (json) {
            return (
                <Card className={"animated zoomIn custom-card result-card"}>
                    <Card.Header>
                        {cardTitle}
                    </Card.Header>
                    <Table>
                        <colgroup>
                            <col width="50%"/>
                            <col width="40%"/>
                        </colgroup>
                        <tbody>
                        {Object.keys(json).map((key) => {
                            return (
                                <tr key={key}>
                                    {this.renderTdWithTooltipIfNecessary(key, json[key])}
                                    {this.renderTdWithColorIfNecessary(json[key].val)}
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Card>
            )
        }
    }

    renderTdWithTooltipIfNecessary(key, value) {
        if (value.tooltip) {
            let popover = <Popover id="popover-positioned-right">{value.tooltip}</Popover>

            return (
                <td>
                    {key}
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={popover}
                        placement="right"
                        delayShow={50}
                        delayHide={100}
                    >
                        <sup><FontAwesome name='question-circle-o'/></sup>
                    </OverlayTrigger>
                </td>
            );
        } else {
            return (
                <td>{key}</td>
            )
        }
    }

    renderTdWithColorIfNecessary(val) {
        let classToUse = ""

        if (val === "Detected") {
            classToUse = "detected-text"
        } else if (val === "None Detected") {
            classToUse = "none-detected-text"
        }

        return (<td className={classToUse}>{val}</td>)
    }

    onChangeVin(event) {
        this.setState({vin: event.target.value})
    }

    submit(event) {
        event.preventDefault()
        this.queryForNewReport(this.state.vin)
    }

    queryForNewReport(vin) {
        this.props.reportActions.loadReport(vin, this.props.navigate)
        this.setState({vin: ""})
    }
}

ReportPage.propTypes = {
    report: PropTypes.object.isRequired,
    error: PropTypes.object,
    reportActions: PropTypes.object.isRequired,
    jwtActions: PropTypes.object.isRequired,
}

function mapStateToProps(state, ownProps) {
    return {
        report: state.report,
        error: state.error,
        jwtResult: state.jwtResult,
        ajaxCallsInProgress: state.ajaxCallsInProgress,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        reportActions: bindActionCreators(reportActions, dispatch),
        jwtActions: bindActionCreators(jwtActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportPage));
