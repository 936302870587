import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import {connect} from 'react-redux';
import Navigation from './components/Navigation'
import * as jwtActions from './actions/jwtActions'
import {bindActionCreators} from 'redux'
import UnsupportedBrowserMessage from './components/unsupportedBrowser/UnsupportedBrowserMessage'
import PropTypes from 'prop-types'

import 'whatwg-fetch'; // FormData needs IE10+

import 'bootswatch/readable/bootstrap.min.css'
import 'animate.css/animate.min.css'

import './css/App.css';

class App extends Component {
    render() {
        return (
            <div>
                <UnsupportedBrowserMessage/>
                <Navigation
                    jwtResult={this.props.jwtResult}
                    logout={this.props.actions.logout}
                    companyName={this.props.jwtResult.companyName}
                >
                    {this.props.children}
                </Navigation>
                <Container>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}

App.propTypes = {
    children: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        loading: state.ajaxCallsInProgress > 0,
        jwtResult: state.jwtResult,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(jwtActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
