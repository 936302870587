import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ErrorCard from '../ErrorCard'

class NoDataCard extends Component {

    render() {
        return (
            <ErrorCard header="No Data for VIN">
                <p>
                    There is currently no data available for the VIN <strong>{this.props.vin}</strong> you entered.
                </p>
                <p>
                    Although rare, this situation typically arises with new vehicles.
                </p>
            </ErrorCard>
        )
    }

}

NoDataCard.propTypes = {
    vin: PropTypes.string.isRequired
}

export default NoDataCard