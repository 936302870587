import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function reportReducer(state = initialState.report, action) {
    switch (action.type) {
        case types.REPORT_SEARCH_BEGUN:
            return initialState.report;
        case types.LOGOUT:
            return initialState.report;
        case types.LOGIN_FAILURE:
            return initialState.report;
        case types.REPORT_SEARCH_SUCCESS:
            return action.report;
        default:
            return state;
    }
}