import pjson from './../package.json';

// export const SERVER_URL = 'http://bua01adi11a.di.carfax.us:8080/';
export const SERVER_URL = window.location.host.indexOf('localhost:3000') !== -1 ? 'http://localhost:8080/' : '/';
export const CLIENT_VERSION = pjson.version;
export const REACT_VERSION = pjson.dependencies.react;
export const MEDIA_PREFIX = window.location.host.indexOf('alpha') !== -1 || window.location.host.indexOf('localhost') !== -1 ? 'alpha' :
    (window.location.host.indexOf('beta') !== -1 ? 'beta' : ''); // default to prod

        // --------------- a poor man's user mgmt ----------------

window.createUser = (username, password, compCode) => {
    window.doItDoItNow('api/createUser?username=' + username + '&password=' + password + '&compCode=' + compCode)
}

window.deleteUser = (username) => {
    window.doItDoItNow('api/deleteUser?username=' + username)
}

window.changePassword = (username, password) => {
    window.doItDoItNow('api/changePassword?username=' + username + '&password=' + password)
}

window.showUsers = () => {
    window.doItDoItNow('api/showUsers', json => {
        json.users.forEach(user => {
            console.log(JSON.stringify(user))
        })
    })
}

window.doItDoItNow = (path, callback) => {
    fetch(SERVER_URL + path, {
        headers: new Headers({
            "Authorization" : "Bearer " + localStorage.getItem('access_token')
        })
    })
    .then(response => {
        if (response.ok) {
            console.log('you did it!')
            if (response.json) {
                try {
                    response.json().then(json => callback(json))
                } catch (all) {}
            }
        } else {
            console.log('something went wrong ~')
        }
    })
    .catch(error => {
        console.error('Error connecting to server: ' + error)
        throw(error);
    });
}
