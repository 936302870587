import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import '../css/components/Navigation.css';
import {MEDIA_PREFIX} from "../config"

class Navigation extends React.Component {
	render() {
		return (
			<header style={{background: "url('https://"+MEDIA_PREFIX+"media.carfax.com/img/big/bua_header.jpg') no-repeat 0 100%", backgroundSize:'cover'}}>
				<Container>
					<Row>
						<Col sm={12}>
                            <span className="siteName" style={{display: 'block'}}>Milewise Mileage Assistant</span>
						</Col>
					</Row>
				</Container>
			</header>
		);
	}

}

export default Navigation;
