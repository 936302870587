import React from "react"
import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import {Form, FormGroup, FormControl, Button, Row, Col, InputGroup, Card} from "react-bootstrap"
import * as jwtActions from "../../actions/jwtActions"
import {withRouter} from "../../wrapper/withRouter";

class LoginPage extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            username: '',
            password: ''
        };

        this.onChangeUsername = this.onChangeUsername.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
        this.submit = this.submit.bind(this)
    }

    render() {
        return (
            <div>
                <Row>
                    <Col className="width-100-percent col-xs-offset-2 col-sm-offset-3 col-md-offset-4"
                         xs={8} sm={7} md={5} lg={4}
                    >
                        {this.renderCredentials()}
                    </Col>
                </Row>
                <Row>
                    <Col
                        className={"col-sm-offset-2 col-md-offset-3 col-lg-offset-3"}
                        xs={12} sm={9} md={7} lg={6}
                    >
                        {this.renderMessage()}
                    </Col>
                </Row>
            </div>
        )
    }

    renderCredentials() {
        const isLoginDisabled = this.props.ajaxCallsInProgress > 0
        const loadingClass = this.props.ajaxCallsInProgress > 0 ? 'loading' : ''
        const textVisibility = this.props.ajaxCallsInProgress > 0 ? 'hidden' : 'visible'

        return (
            <Form onSubmit={this.submit}>
                <FormGroup>
                    <InputGroup>
                        <FormControl autoFocus={true} type="text" value={this.state.username} id="username"
                                     onChange={this.onChangeUsername} placeholder="Username"/>
                        <FormControl type="password" value={this.state.password} id="password"
                                     onChange={this.onChangePassword} placeholder="Password"/>
                        <Button disabled={isLoginDisabled} className={loadingClass} variant={"success"} type={"submit"}
                                id={"login"}>
                            <span style={{visibility: textVisibility}}>
                                Login
                            </span>
                        </Button>
                    </InputGroup>
                </FormGroup>
            </Form>
        )
    }

    renderMessage() {
        if (this.props.jwtResult.failureMessage) {
            return (
                <Card className="animated zoomIn custom-card">
                    <Card.Header>
                        Please Try Again
                    </Card.Header>
                    <Card.Body style={{color: "red"}}>
                        {this.props.jwtResult.failureMessage}
                    </Card.Body>
                </Card>
            )
        } else if (this.props.jwtResult.loginSessionExpired) {
            return (
                <Card className={"animated zoomIn custom-card"}>
                    <Card.Header>
                        Session Timed-out
                    </Card.Header>
                    <Card.Body style={{color: "red"}}>
                        Your session timed-out due to inactivity. Please login above.
                    </Card.Body>
                </Card>
            )
        }
    }

    onChangeUsername(event) {
        this.setState({username: event.target.value})
    }

    onChangePassword(event) {
        this.setState({password: event.target.value})
    }

    submit(e) {
        e.preventDefault();
        this.props.actions.login(this.state, this.props.navigate);
    }
}

function mapStateToProps(state, ownProps) {
    return {
        jwtResult: state.jwtResult,
        ajaxCallsInProgress: state.ajaxCallsInProgress,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(jwtActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
