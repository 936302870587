import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Row, Col, Card} from 'react-bootstrap'


class ErrorCard extends Component {

    render() {
        return (
            <Card
                className={"custom-card error-card animated zoomIn"}>
                <Card.Header>
                    {this.props.header}
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <div className="error-description">
                                {this.props.children}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>

            </Card>
        )
    }

}

ErrorCard.propTypes = {
    children: PropTypes.array.isRequired,
    header: PropTypes.string.isRequired
}

export default ErrorCard