import React, {Component} from 'react'
import ErrorCard from '../ErrorCard'

class SystemErrorCard extends Component {

    render() {
        return (
            <ErrorCard header="Error">
                <p>
                    We have unfortunately encountered an error in processing your request. Please try again later.
                </p>

                <p>
                    If you continue to experience issues, please contact your administrator.
                </p>

                <p>
                    We apologize for the inconvenience.
                </p>
            </ErrorCard>
        )
    }

}

export default SystemErrorCard