import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ErrorCard from '../ErrorCard'

class InvalidVinCard extends Component {

    render() {
        return (
            <ErrorCard header="Invalid VIN">
                <p>
                    The VIN <strong>{this.props.vin}</strong> you entered is invalid.
                </p>
                <p>
                    Please check the number for accuracy and try again.
                </p>

                Hints:
                <ul>
                    <li>The letters "I", "O", and "Q" never appear in a VIN.</li>
                    <li>The VIN must total exactly 17 characters.</li>
                    <li>Information is only available for cars manufactured since 1981.</li>
                </ul>
            </ErrorCard>
        )
    }

}

InvalidVinCard.propTypes = {
    vin: PropTypes.string.isRequired
}

export default InvalidVinCard