import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function jwtReducer(state = initialState.jwtResult, action) {
    switch (action.type) {
        case types.LOGIN_SUCCESS:
            localStorage.setItem('refresh_token', action.jwtResult.refresh_token);
            localStorage.setItem('access_token', action.jwtResult.access_token);
            localStorage.setItem('companyName', action.jwtResult.companyName);
            return action.jwtResult;
        case types.LOGIN_FAILURE:
            localStorage.setItem('refresh_token', '');
            localStorage.setItem('access_token', '');
            if (action.jwtResult.companyName) localStorage.setItem('companyName', action.jwtResult.companyName);
            return action.jwtResult;
        case types.LOGOUT:
            localStorage.setItem('refresh_token', '');
            localStorage.setItem('access_token', '');
            if (action.jwtResult.companyName) localStorage.setItem('companyName', action.jwtResult.companyName);
            return action.jwtResult;
        default:
            return state;
    }
}