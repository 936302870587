export default {
    report             : {},
    error              : {},
    ajaxCallsInProgress: 0,
    jwtResult : {
        refresh_token : localStorage.getItem('refresh_token'),
        access_token : localStorage.getItem('access_token'),
        companyName : localStorage.getItem('companyName'),
    }
}
