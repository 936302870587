import * as types from './actionTypes'
import * as ajaxActions from './ajaxStatusActions'
import * as reportActions from './reportActions'
import {SERVER_URL} from "../config"

export function loginSuccess(jwtResult) {
    return {type: types.LOGIN_SUCCESS, jwtResult}
}

export function loginFailure(failureMessage) {
    return {type: types.LOGIN_FAILURE, jwtResult: {failureMessage}}
}

export function logout(jwtResult = {}, navigate) {
    // Please note: No state is kept on the server so no need to hit the server to logout
    jwtResult.companyName = localStorage.getItem('companyName'); // keep company name around forever
    if (navigate) {
        setTimeout(() => navigate("/"));
    }
    return {type: types.LOGOUT, jwtResult};
}

export function login(usernameAndPassword, navigate) {
    return (dispatch) => {
        dispatch(ajaxActions.beginAjaxCall())
        fetch(SERVER_URL + 'api/login', {method: "POST", body: JSON.stringify(usernameAndPassword)})
            .then(response => response.json().then(json => ({is200: response.ok, json})))
            .then(({is200, json}) => {
                if (is200) {
                    // before BUA-21 removed companyName from the view
                    json.companyName = '';
                    if (window.theyMadeARequestForThisVinThatRequiredALogin) {
                        let vin = window.theyMadeARequestForThisVinThatRequiredALogin;
                        window.theyMadeARequestForThisVinThatRequiredALogin = null;
                        localStorage.setItem('access_token', json.access_token);
                        dispatch(reportActions.loadReport(vin, navigate));
                    }
                    dispatch(loginSuccess(json));

                    setTimeout(() => navigate("/report"));
                } else {
                    return loginFailure(json.message);
                }
            })
            .then(action => dispatch(action))
            .then(() => dispatch(ajaxActions.endAjaxCall()))
            .catch(error => {
                dispatch(ajaxActions.endAjaxCall());
            })
    }
}

export function refreshToken() {
    return (dispatch) => {
        if (typeof FormData === "undefined") return
        dispatch(ajaxActions.beginAjaxCall())

        let formData = new FormData()
        formData.append('grant_type', 'refresh_token')
        formData.append('refresh_token', localStorage.getItem("refresh_token"))

        fetch(SERVER_URL + 'oauth/access_token', {method: "POST", body: formData,})
            .then(response => response.json().then(json => ({is200: response.ok, json})))
            .then(({is200, json}) => {
                if (is200) {
                    localStorage.setItem('access_token', json.access_token)
                    localStorage.setItem('refresh_token', json.refresh_token)
                } else {
                    dispatch(logout({}));
                }
            })
            .then(() => dispatch(ajaxActions.endAjaxCall()))
            .catch(error => {
                dispatch(ajaxActions.endAjaxCall())
            })
    }
}

export function isTokenValid(navigate) {
    return (dispatch) => {
        dispatch(ajaxActions.beginAjaxCall())
        const options = {
            headers: new Headers({"Authorization": "Bearer " + localStorage.getItem('access_token')})
        }

        return fetch(SERVER_URL + 'api/validate', options)
            .then(response => {
                if (response.ok || response.status === 422) {
                    return response.json().then(json => ({is200: response.ok, status: response.status, json}))
                } else {
                    return {is200: false, status: response.status};
                }
            })
            .then(({is200, json, status}) => {
                // TODO: should we ever make more requests to servers than just this one, we should make a generalized way of intercepting 401s and logging out and saving the last request
                dispatch(ajaxActions.endAjaxCall());
                if (status === 401) {
                    dispatch(
                        logout(
                            {loginSessionExpired: true, companyName: localStorage.getItem('companyName')},
                        )
                    );
                    return false;
                } else { // since the old access_token hasn't expired, get a new one so that it lasts another 30 minutes
                    dispatch(refreshToken(navigate));
                    return true;
                }
            })
            .catch(error => {
                dispatch(ajaxActions.ajaxCallErroredOut());
                throw(error);
            });
    }
}

/**
 * Once we have more than one user, we'll need to support multiple different companyNames (right now it's hardcoded to our only user).
 *
 * The authentication failure mechanism was overloaded by Todd to also send authentication failure reasons to the view.  The same sort
 * of thing should be possible to support companyName (instead of making a separate request for it form the view, it makes sense to pass
 * it along in the login request).
 *
 * To get a hold of the login 'success' event, look in RestAuthenticationFailureHandler or in DefaultAccessTokenJsonRenderer.
 *
 * @deprecated
 */
export function loadCompanyName(jwtResult, navigate) {
    return (dispatch) => {
        dispatch(ajaxActions.beginAjaxCall())
        fetch(SERVER_URL + 'api/companyName', {headers: new Headers({"Authorization": "Bearer " + jwtResult.access_token})})
            .then(response => response.json().then(json => ({is200: response.ok, json})))
            .then(({is200, json}) => {
                if (is200) {
                    jwtResult.companyName = json.companyName
                    if (window.theyMadeARequestForThisVinThatRequiredALogin) {
                        let vin = window.theyMadeARequestForThisVinThatRequiredALogin
                        window.theyMadeARequestForThisVinThatRequiredALogin = null
                        localStorage.setItem('access_token', jwtResult.access_token)
                        dispatch(reportActions.loadReport(vin, navigate))
                    }
                    return loginSuccess(jwtResult)
                } else {
                    return loginFailure()
                }
            })
            .then(action => dispatch(action))
            .then(() => dispatch(ajaxActions.endAjaxCall()))                                              // Indicate that our ajax call is
            // complete
            .catch(error => {
                console.error('Error connecting to server: ' + error)
                dispatch(ajaxActions.ajaxCallErroredOut())
                throw(error)
            })
    }
}
