import React from 'react';
import UAParser from 'ua-parser-js'

import '../../css/components/unsupportedBrowser/UnsupportedBrowser.css'

class UnsupportedBrowserMessage extends React.Component {
	constructor() {
		super()
		this.MIN_SUPPORTED_VERSIONS = {
			'Chrome': 22,
			'Firefox': 27,
			'IE':11,
			'Edge':12,
			'Safari':7
		}
		this.parser = UAParser
		this.browserInfo = this.parser(window.navigator.userAgent).browser
	}

	render() {
		if(this.isUnsupportedVersion()) {
			return (
				<div>
					{this.renderUnsupportedBrowserMessage()}
				</div>
			);
		} else {
			return null
		}
	}

	renderUnsupportedBrowserMessage() {
		return (
			<div id="unsupportedBrowserMessage">
				<b>WARNING! You are using an outdated version of {this.browserInfo.name}.</b><br/>
				To provide the best and most secure experience, our website will no longer support this version beginning September 5, 2017.<br/>
				Please upgrade your browser to version {this.MIN_SUPPORTED_VERSIONS[this.browserInfo.name]} or greater to continue using our site.
			</div>
		)
	}

	isUnsupportedVersion() {
		if(this.isKnownBrowser()){
			let majorVersion = this.browserInfo.version && this.browserInfo.version.split('.')[0]
			return majorVersion < this.MIN_SUPPORTED_VERSIONS[this.browserInfo.name]
		} else {
			return false
		}
	}

	isKnownBrowser() {
		return this.MIN_SUPPORTED_VERSIONS[this.browserInfo.name] !== undefined
	}
}

export default UnsupportedBrowserMessage;
